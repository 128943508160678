import React,  {useContext,useState,useEffect }from 'react';
import ReactCompareImage from 'react-compare-image';
import '../css/ServiceList.css';
import Navbar  from './Nabar';
import Header from './Header';
import Footer from './Footer';

import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 





const Construction = (props) => {
  console.log(props.finishingList)

  const [selectedItem, setSelectedItem] = useState(null);
  // const [state, dispatch] = useReducer(furnitureReducer, initialState);
  const [construction, setConstruction] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

// Fetch the data from the API
// useEffect(() => {
//   const fetchConstruction = async () => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}/projects`); 
//       console.log(response.data.data.data)
//       setConstruction(response.data.data.data); 
//       setLoading(false);
//     } catch (err) {
//       setError('Failed to load data');
//       setLoading(false);
//     }
//   };

//   fetchConstruction();
// }, []);

// if (loading) {
//   return <p>Loading...</p>;
// }

// if (error) {
//   return <p>{error}</p>;
// }

  return (
    <div className='bg-gray-100'>
        <Header />
    <div className="service-list-container">
        
    <section className="p-8 bg-gray-100">

<div id="interior" className="">
<h2 className="text-4xl font-bold text-center mb-8">Funishing Transitions</h2>

</div>

<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {props.finishingList.map((project) => (
          <div key={project.id} className="construction-card bg-white shadow-lg rounded-lg p-6 ">

            <ReactCompareImage 
            leftImage={ project.images[0].state == 0 ?`${API_IMAGE_URL}/${project.images[0].image_url}` : null}
            rightImage={project.images[1].state == 1 ?`${API_IMAGE_URL}/${project.images[1].image_url}` : null} 
            sliderPositionPercentage={0.5}  // Initial slider position
            aspectRatio="wider"  
          />
          

            <h2 className="text-2xl font-bold mb-2">{project.project_title}</h2>
            <p className="text-gray-600">{project.description}</p>
          </div>
        ))}
      </div>
</section>
    </div>
    <Footer />
    </div>
  );
};

export default Construction;



