import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_BASE_URL, API_IMAGE_URL } from '../config';
import { format } from 'date-fns';
import TruncateText from "../TruncateText";

const ProjectBYCategory = () => {
  const { id } = useParams();
  const [projects, setProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/projects/type/${id}`);
        for (let index = 0; index < response.data.data.data.length; index++) {
          setProject((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]]); 

        }
        // setProject(response.data.data);
        // setSelectedImage(response.data.data.images[0].image_url); 
        setLoading(false);
      } catch (err) {
        setError('Failed to load project data');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  if (loading) return <p>Loading project...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="">
      {/* Header */}
      <Header />
      <div className="service-list-container mx-auto ">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
             <Link to={`/projects/${project.id}`} key={project.id}>
              <div className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer">
                <img
                  src={`${API_IMAGE_URL}/${project.images[0].image_url}`}
                  alt={project.project_title}
                  className="w-full h-60 object-cover transition-transform duration-500 transform group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                  <h3 className="text-white text-xl font-bold">{project.project_title}</h3>
                  {/* <button  className='profileButton' >
                                More
                            </button> */}
                </div>
                <div className="p-4">
                  <h3 className="text-gray-700 italic">
                    
                    <TruncateText text={project.description} limit={100} />
                  </h3>
                </div>

              </div>
              </Link>
           
          ))}
        </div>
        </div>

      
      {/* <div className="project-detail-container mx-auto">
        <button onClick={() => navigate(-1)} className="underline mb-2 hover:opacity-100" style={{color:"black",marginRight:"50%",backgroundColor:"white"}}>Back to Portfolio</button>

        <div className="flex flex-col lg:flex-row items-start gap-8">
       

          <div className="main-image-container mb-4   lg:w-3/4  mx-auto ">
          <img
            src={`${API_IMAGE_URL}/${selectedImage}`}
            alt={project.project_title}
            className="w-full h-80 object-cover rounded-lg"
          />
        </div>

         
          <div className="thumbnail-gallery flex lg:flex-col gap-4 lg:gap-2">
            {project.images.map((img, index) => (
              <img
                key={index}
                src={`${API_IMAGE_URL}/${img.image_url}`}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setSelectedImage(img.image_url)}
                className={`w-20 h-20 object-cover rounded-lg cursor-pointer border-2 ${
                  selectedImage === img.image_url ? 'border-yellow-500' : 'border-transparent'
                }`}
              />
            ))}
          </div>
        </div>

      
        <div className="mt-8 " style={{width:"68%"}}>
          <h2 className="text-3xl font-bold mb-2">{project.project_title}</h2>
          <h4 className="text-lg mb-4">Company: {project.client_name}</h4>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Description</h4>
            <p>{project.description}</p>
          </div>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Project Time</h4>
            <p>{format(new Date(project.start_date), 'yyyy-MM-dd')} - {format(new Date(project.end_date), 'yyyy-MM-dd')}</p>
          </div>
        </div>
      </div> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ProjectBYCategory;
