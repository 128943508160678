import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const ScrollButton = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  // Function to handle scroll
  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    
    setIsAtBottom(scrollTop + windowHeight >= scrollHeight - 50); // Adjust this value for fine-tuning
  };

  // Attach the scroll listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll up or down
  const toggleScroll = () => {
    if (isAtBottom) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <button
      onClick={toggleScroll}
      className="scroll-button"
    >
       {isAtBottom ? <FaArrowUp size={30} /> : <FaArrowDown size={30} />}
    </button>
  );
};

export default ScrollButton;
