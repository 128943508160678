import React, { useState, useEffect ,} from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { API_BASE_URL, API_IMAGE_URL } from '../config';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

const ProjectMetaTags = () => {
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [metaData, setMetaData] = useState(null);
 

  const location = useLocation();

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
    const response = await axios.get(`${API_BASE_URL}/keyword`);
    const pageData = response.data.data.data.filter(
      (item) => item.page_url.trim() === location.pathname.trim()
    );
    console.log(pageData)

    const title = pageData.find((item) => item.keyword_type === "title")?.keyword || "Majestic Trading PLC.";
    const content = pageData.find((item) => item.keyword_type === "content")?.keyword || "Welcome to Majestic, your trusted partner in shaping spaces, identities, and digital experiences.Nestled in the heart of Addis Ababa, Ethiopia, we bring three years of passionate dedication to interior design, finishing, construction, branding, website design, and system design.";
    console.log(title);
    console.log(content)
    setTitle(title)

    setMetaData({ title, content });
  } catch (error) {
    console.error("Error fetching keywords data:", error);
  }
};
fetchProjectData();
  }, [location.pathname]);

  if (!title) return null; 
  console.log("location " ,location.pathname)
  console.log("************")
  console.log("metaData ", metaData.title)

  return (

    <HelmetProvider>
    {metaData && (
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.content} />
          <meta property="og:title" content={metaData.title} />
          <meta property="og:description" content={metaData.content} />
          <meta name="twitter:card" content={metaData.content} />
        </Helmet>
      )}
     
      
  </HelmetProvider>

  );
};

export default ProjectMetaTags;
