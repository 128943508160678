import React, { useState, useEffect } from 'react';
import { BiFontFamily } from 'react-icons/bi';

const ProjectCounter = ({ targetCount = 100, iconColor = '#ff5733'  }) => {
    const [count, setCount] = useState(0); // Initial count set to 0

    useEffect(() => {
        let currentCount = 0;
        const increment = Math.ceil(targetCount / 100); // Increment based on targetCount

        const counterInterval = setInterval(() => {
            currentCount += increment;
            if (currentCount >= targetCount) {
                setCount(targetCount); // Set to the final count
                clearInterval(counterInterval); // Stop the interval
            } else {
                setCount(currentCount); // Update the count
            }
        }, 200); // 30ms delay for smoother animation

        return () => {
            clearInterval(counterInterval); // Cleanup the interval on unmount
        };
    }, [targetCount]);

    return (
        <div className="project-counter" style={styles.counterContainer}>
            <div style={styles.iconAndNumber}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-16" style={{ ...styles.icon, color: iconColor }}>
  <path fillRule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
  <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
</svg>

            <h1 style={styles.counterNumber}>{count}+</h1>
            <p style={styles.counterText}>Projects</p>
            </div>
        </div>
    );
};

const styles = {
    iconAndNumber: {
        display: 'flex',
        flexDirection: 'column', // Display items in a vertical column
        alignItems: 'center',
        justifyContent: 'center',
        // color:"#3cc7f3"
    },
    icon: {
        fontSize: '2.5rem',
        marginRight: '10px',
        color: '#ffffff', // You can change the icon color here
    },
    counterContainer: {
        position: 'absolute', // Fix the position
        bottom: '80px',    // 20px from the bottom
        right: '100px',     // 20px from the right
        padding: '10px 20px',
        backgroundColor: 'rgba(0.1, 0.2, 0, 0.4)',
        color: 'white',
        borderRadius: '20%',
        textAlign: 'center',
        zIndex: 1000, 
        width:"200px"
    },
    counterNumber: {
        fontSize: '5rem',
        margin: '0',
    },
    counterText: {
        fontSize: '1.5rem',
        margin: '0',
     
    },
};

export default ProjectCounter;
