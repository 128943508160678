import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_BASE_URL, API_IMAGE_URL } from '../config';
import TruncateText from "../TruncateText";

const Portfolio = (props) => {
  const [portfolioList, setPortfolioList] = useState([]);
  const [filteredPortfolio, setFilteredPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [category, setCategory] = useState([]);

  const categories = ['All', 'Web Development', 'Mobile Development', 'Data Science','Mobile Development', 'Data Science'];

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/projects`);
        setPortfolioList(response.data.data.data);
        setFilteredPortfolio(response.data.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };
    fetchPortfolio();
  }, []);

  const filterByCategory = (category) => {
    console.log(portfolioList)
    setSelectedCategory(category);
    const filtered =
      category === 'All'
        ? portfolioList
        : portfolioList.filter((project) => project.category['sub_category'] === category);
    setFilteredPortfolio(filtered);
  };

  useEffect(() => {
    const fetchcategory = async () => {
      try {
         const response = await axios.get(`${API_BASE_URL}/category`); 
         // const response = {"success":true,"data":{"data":[{"main_category_id":3,"main_category_name":"Construction","status":"active","sub_categories":[{"sub_category_id":3,"sub_category_name":"interior","cover_image":"uploads/Interior01-1730884247304-927532073.jpg","status":"active"}]},{"main_category_id":2,"main_category_name":"Design","status":"active","sub_categories":[{"sub_category_id":2,"sub_category_name":"Hotel Interior Design","cover_image":"uploads/Interior01-1730884247304-927532073.jpg","status":"active"}]},{"main_category_id":1,"main_category_name":"Renovation","status":"active","sub_categories":[{"sub_category_id":1,"sub_category_name":"Interior Renovation","cover_image":"uploads/Interior01-1730884247304-927532073.jpg","status":"active"}]}],"pagination":{"currentPage":1,"pageSize":10,"totalCount":3,"totalPages":1,"hasNextPage":false,"hasPreviousPage":false}}}
        console.log(response.data.data.data.length)
        for (let index = 0; index < response.data.data.data.length; index++) {

          console.log("it is working" , index)
          for (let index1 = 0; index1 < response.data.data.data[index]['sub_categories'].length; index1++) {
            console.log(index1)
          setCategory((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]['sub_categories'][index1]]); 
          // setCategory((prevPortfolioList) => [...prevPortfolioList, ...index1]);
          }
        }
       
        
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };
    
    fetchcategory();
  
  }, []);

  return (
    <div id="portfolio" className="portfolio-section">
      <div className="portfolio-header">
        <Header />
      </div>

      <div className="portfolio-container mx-auto">
        <h1 className="text-center text-4xl font-bold mb-8">Portfolio</h1>

        {loading && <p>Loading projects...</p>}
        {error && <p className="text-red-500">{error}</p>}

        {/* <div className="flex justify-center gap-4 mb-8">
         
          {category.map((category) => (
           
            <button
              key={category.sub_category_id}
              onClick={() => filterByCategory(category.sub_category_name)}
              className={`px-4 py-2 rounded ${
                selectedCategory === category.sub_category_name ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-500'
              }`}
            >
              {category.sub_category_name}
            </button>
          ))}
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {category.map((project) => (
            <Link to={`/projects/type/${project.sub_category_id}`} key={project.sub_category_id}>
              <div className="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer">
                <img
                  src={`${API_IMAGE_URL}/${project.cover_image}`}
                  alt={project.sub_category_name}
                  className="w-full h-60 object-cover transition-transform duration-500 transform group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                  <h3 className="text-white text-xl font-bold">{project.sub_category_name}</h3>
                  {/* <button  className='profileButton' >
                                More
                            </button> */}
                </div>
                <div className="p-4">
                  <h3 className="text-gray-700 italic">
                    
                    {/* <TruncateText text={project.description} limit={100} /> */}
                  </h3>
                </div>

              </div>
            </Link>
          ))}
        </div>
      </div>

      <div style={{ paddingTop: '10px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Portfolio;
