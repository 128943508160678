import React, { useState,useReducer,useEffect,useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 
import { furnitureReducer, initialState } from '../Reducer/furnitureReducer';



const Furniture = (props) => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  // const [state, dispatch] = useReducer(furnitureReducer, initialState);
  const [service, setService] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

const iframeRef = useRef(null);
const [iframeHeight, setIframeHeight] = useState(500); // Set default height

const handleIframeLoad = () => {
  if (iframeRef.current) {
    const iframeContent = iframeRef.current.contentWindow.document.body;
    setIframeHeight(iframeContent.scrollHeight);
  }
};

// Fetch the data from the API
useEffect(() => {
  const fetchService = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/services/${id}`);
      console.log("each service ",response.data.data)
      // for (let index = 0; index < response.data.data.length; index++) {
      // console.log("each service ",response.data.data)
      setService(response.data.data); 
      //setService((prevPortfolioList) => [...prevPortfolioList, response.data.data[index]['sub_categories'][index1]]); 
      // }
      // setLoading(false);
    } catch (err) {
      // setError('Failed to load data');
      // setLoading(false);
    }
  };

  fetchService();
}, [id]);

// if (loading) {
//   return <p>Loading...</p>;
// }

// if (error) {
//   return <p>{error}</p>;
// }



  return (
    <div className='bg-gray-100 mobileView mt-6' >
        <Header />
    <div className="interior-design-list-container">
    <img
              src={`${API_IMAGE_URL}/${service.cover_image}`}
              alt={service.title}
              className="w-full h-64 object-cover rounded-t-lg"
            />     
     
     <div 
  className="flex justify-center items-center w-full pt-12 px-8" 
  style={{ height: "100%" }}
>
  <iframe
    srcDoc={service.content}
    scrolling="no"
    ref={iframeRef}
    onLoad={handleIframeLoad}
    style={{
      width: "100%",
      maxWidth: "80%", // Adjust max width for responsiveness
      height: `${iframeHeight}px`,
      border: "none",
      marginBottom:"2%"
    }}
    sandbox="allow-same-origin allow-scripts"
  ></iframe>
</div>


      
    </div>
    <Footer />
    </div>
  );
};

export default Furniture;



