import React, { useEffect,useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { FaUsers, FaCalendarAlt, FaProjectDiagram } from 'react-icons/fa';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ProjectMetaTags from './ProjectMetaTags';

const About = () => {
  const [aboutInfo, setAboutInfo] = useState({
    description: "",
    mission: "",
    vision: "",
    year: "",
  });
  const [projectCounter ,setProjectCounter] = useState(0);




 useEffect(()=>{
  const fetchPages = async () => {
    try {
       const response = await axios.get(`${API_BASE_URL}/pages/all`);
     console.log(response.data.data.data)
      for (let index = 0; index < response.data.data.data.length; index++) {
      //   console.log(response.data[index])
      const element = response.data.data.data[index];
 
     
      if(element.name.includes("aboutUs")){
        for (let index1 = 0; index1 < element.sections.length; index1++) {
          const validKeys = ["description", "mission", "vision", "year"];
          const sectionName = element.sections[index1].name;
          console.log(sectionName)

        if (validKeys.includes(sectionName)) {
        
          setAboutInfo((prevState) => ({
            ...prevState,
            [sectionName]: element.sections[index1].content,
          }));

        // if(element.sections[index1].name.includes("description")){
        //   console.log(element.sections[index1].content)
        //   setAboutInfo((prevState) => ({
        //     ...prevState,
        //     ["year"]: element.sections[index1].content,
        //   }));

        // }

      //   if(element.sections[index1].name == "description"){
      //     console.log(element.sections[index1].content)
      //     setAboutInfo("description",element.sections[index1].content)

      //   }
      //  else if(element.sections[index1].name == "mission"){
      //     setAboutInfo("mission",element.sections[index1].content)

      //   }
      //  else if(element.sections[index1].name == "vision"){
      //     setAboutInfo("vision",element.sections[index1].content)

      //   }
      //  else if(element.sections[index1].name == "year"){
      //     setAboutInfo("year",element.sections[index1].content)

      //   }
     
       
      }}}
    

        
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }

  };


  const fetchCounter = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/counter`);
      console.log("this is video ",response.data.data.data)
      for (let index = 0; index < response.data.data.data.length; index++) {
        const element = response.data.data.data[index];
        // console.log(element.project_type)
        // console.log(getSimilarCategories("all", "all", 50))
        // console.log(getSimilarCategories("interior", element.project_type, 50))

      if("all" ==  element.project_type){
        console.log(true)
        console.log(index)
        setProjectCounter(element.amount)
       
      }
   
        
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  fetchPages();
  fetchCounter()
 },[])

 useEffect(() => {
  console.log("Updated aboutInfo:", aboutInfo.description);
}, [aboutInfo]);
  return (
    <div>


      <Header />
 
<section id="about-container" className="py-16 bg-white ">
<ProjectMetaTags /> 
      <div className="container mx-auto px-6">

      <div className="text-center mb-10 ">
          <h1 className="text-5xl font-bold mb-4 text-gray-900">About Us</h1>
          <h1 className="text-lg max-w-2xl mx-auto bg-gray-100 p-8 rounded-lg shadow-lg">
          {aboutInfo.description}

          </h1>
        </div>

        {/* Introduction Section */}
        <div className="flex flex-col lg:flex-row items-center mb-12">
         
         
      
{/*         
          <div className="lg:w-1/2 w-full p-4 bg-gray-100 p-8 rounded-lg shadow-lg">
            <h2 className="text-4xl font-bold text-gray-800 mb-6">About Us</h2>
            <p className="text-lg text-gray-600 mb-4">
            Welcome to Majestic, your trusted partner in shaping spaces, identities, and digital experiences. Nestled in the heart of Addis Ababa, Ethiopia, we bring three years of passionate dedication to interior design, finishing, construction, branding, website design, and system design.

Our Journey

In just three years, Majestic has
emerged as a creative powerhouse, dedicated to redefining environments and
brand narratives. Our journey has been marked by a commitment to excellence,
continuous learning, and a relentless pursuit of innovative solutions.
            </p>
            <a href="#contact" className="bg-gray-500 text-white py-2 px-6 rounded-lg hover:bg-gray-700 transition duration-300">
              Get in Touch
            </a>
          </div>

          <div className="lg:w-1/2 w-full p-4 bg-gray-100 p-8 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold text-gray-800 mb-6">Our Journey</h2>
          <p className="text-lg text-gray-600 mb-4">

In just three years, Majestic has
emerged as a creative powerhouse, dedicated to redefining environments and
brand narratives. Our journey has been marked by a commitment to excellence,
continuous learning, and a relentless pursuit of innovative solutions.</p>
            </div> */}
         
        </div>

        {/* Mission and Vision Section */}
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Mission */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-semibold text-regal-blue mb-4">Our Mission</h3>
            <p className="text-lg text-gray-600">
            {aboutInfo.mission}
            </p>
          </div>
      
          {/* Vision */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-semibold text-regal-blue mb-4">Our Vision</h3>
            <p className="text-lg text-gray-600">
            {aboutInfo.vision}
            </p>
          </div>
        </div>


                {/* Stats Section */}
                <div className="flex justify-around items-center mt-16">
          {/* <div className="text-center">
            <FaUsers className="text-6xl text-gray-400 mb-4 ml-10"  />
            <h3 className="text-4xl font-bold">200</h3>
            <p className="text-gray-400">Happy Customers</p>
          </div> */}
          <div className="text-center">
            <FaCalendarAlt className="text-6xl text-gray-400 mb-4 ml-10" />
            <h3 className="text-4xl font-bold"> {aboutInfo.year}</h3>
            <p className="text-gray-400">Established Year </p>
          </div>
          <div className="text-center">
            <FaProjectDiagram className="text-6xl text-gray-400 mb-4 ml-10" />
            <h3 className="text-4xl font-bold">{projectCounter}</h3>
            <p className="text-gray-400">Completed Projects</p>
          </div>
        </div>
      </div>
    </section>
    <div id='contact'>
    <Footer />
    </div>
  
    </div>

  );
};

export default About;
