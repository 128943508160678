import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { API_BASE_URL, API_IMAGE_URL } from '../config';
import { format } from 'date-fns';
import TruncateText from "../TruncateText";
import ReactCompareImage from 'react-compare-image';
import '../css//ProjectList.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ProjectDetail = () => {
  const { id } = useParams();
 
  const [project, setProject] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const [isConstruction, setIsConstruction] = useState(false);
  const [before, setBefore] = useState(null);
  const [after, setAfter] = useState(null);
  const [num, setNum] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const toggleDiv = () => {
    setIsVisible(!isVisible);
  };


  useEffect(() => {
   
    const fetchProjects = async () => {
      console.log("project lenght ",projects.length)
      if(projects.length <= 0){
      // setProjects([])
      try {
        const response = await axios.get(`${API_BASE_URL}/projects?pageSize=20`);
        for (let index = 0; index < response.data.data.data.length; index++) {
          setProjects((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]]); 

        }

        setLoading(false);
      } catch (err) {
        setError('Failed to load project data');
        setLoading(false);
      }}
    };
    fetchProjects();
  }, []);
  useEffect(() => {
    const fetchProject = async () => {
      setNum(0)
      try {
        const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
        // for (let index = 0; index < response.data.data.data.length; index++) {
        //   setProject((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]]); 

        // }
        setProject(response.data.data);
        console.log(response.data.data)
        setSelectedImage(response.data.data.images[0].image_url); 
       
        if(response.data.data.images.length > 1){
          console.log(response.data.data.id)

          for (let index = 0; index < response.data.data.images.length; index++) {
            const element = response.data.data.images[index].state;
            
            console.log((element == 1))
            if((element == 0)){
              console.log("before")
            // setNum(num + 1)
            setNum((prev) => (prev +1)); 
            setBefore(response.data.data.images[index].image_url)
            }
            if((element == 1)){
              console.log("after")
              setNum((prev) => (prev +1)); 
              setAfter(response.data.data.images[index].image_url)
              }
            // if(response.data.data.)mages[index].state == 0){
            //   setBefore(response.data.data.images[index].image_url)
            //   console.log("before")
            //   num = num + 1
            // }
            // else if(response.data.data.images[index].state == 0){
            //   setAfter(response.data.data.images[index].image_url)
            //   console.log("after")
            //   num = num + 1
            // }
           
            
            
          }
        
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to load project data');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  useEffect(()=>{
    console.log("num value ", num)
    if(num == 2){
      setIsConstruction(true)
    }else{
      setIsConstruction(false)
    }
  },[num])

  if (loading) return <p>Loading project...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
 console.log("projects " ,projects.length)

  return (
    <div>
       <HelmetProvider>
      <Helmet>
          <title>{project.project_title}</title>
          <meta name="description" content={project.description} />
          <meta property="og:title" content={project.project_title} />
          <meta property="og:description" content={project.description} />
          <meta name="twitter:card" content={project.description} />
        </Helmet>
        </HelmetProvider>
    
      <Header />

       <div className="lg:w-3/4 mt-24 ">

    

        {(isConstruction == false) && ( 
        <div className='project-detail-container mx-auto'>
      
        <div className="flex flex-col lg:flex-row items-start gap-28">
       <div className='selectedImage'>
       <img
            src={`${API_IMAGE_URL}/${selectedImage}`}
            alt={project.project_title}
            className="w-full h-80 object-cover rounded-lg"
          />
          </div>

          <div className="main-image-container mb-4   lg:w-3/4  mx-auto ">
          <img
            src={`${API_IMAGE_URL}/${selectedImage}`}
            alt={project.project_title}
            className="w-full h-80 object-cover rounded-lg"
          />
        </div> 


         
          <div className="thumbnail-gallery flex lg:flex-col gap-4 lg:gap-4 mt-2">
            {project.images.map((img, index) => (
              <img
                key={index}
                src={`${API_IMAGE_URL}/${img.image_url}`}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setSelectedImage(img.image_url)}
                className={`object-cover rounded-lg cursor-pointer border-2 ${
                  selectedImage === img.image_url ? 'border-yellow-500' : 'border-transparent'
                }`}
              />
            ))}
          </div>
        </div>

      
        <div className="mt-8 " style={{width:"88%"}}>
          <h2 className="text-3xl font-bold mb-2">{project.project_title}</h2>
          <h4 className="text-lg mb-4">Company: {project.client_name}</h4>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Description</h4>
            <p>{project.description}</p>
          </div>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Project Time</h4>
            <p>{format(new Date(project.start_date), 'yyyy-MM-dd')} - {format(new Date(project.end_date), 'yyyy-MM-dd')}</p>
          </div>
        </div>
        </div>
      
        )}


        {(isConstruction )  && (
           <div className=' mx-auto'>


{isVisible && (
   <div key={project.id} className="construction-card  shadow-sm rounded-lg p-6  lg:w-3/4  mx-auto ">


<ReactCompareImage 
leftImage={ `${API_IMAGE_URL}/${before}` }
rightImage={`${API_IMAGE_URL}/${after}`} 
sliderPositionPercentage={0.5}  
aspectRatio="wider"  

/>
<button onClick={toggleDiv} className='project_button mt-4 pl-4'  >
        {isVisible ? "Hide Content" : "Show Before and After"}
      </button>
<h2 className="text-2xl font-bold mb-2 mt-2">{project.project_title}</h2>
<p className="text-gray-600">{project.description}</p>
<h4 className="font-semibold text-lg mb-2">Description</h4>
            <p>{project.description}</p>

            <h4 className="font-semibold text-lg mb-2">Project Time</h4>
            <p>{format(new Date(project.start_date), 'yyyy-MM-dd')} - {format(new Date(project.end_date), 'yyyy-MM-dd')}</p>

</div> 

)}
{(isVisible == false )&& (
        <div className='project-detail-container shadow-sm rounded-lg p-6  lg:w-3/4  mx-auto'>



       
        <div className="flex flex-col lg:flex-row items-start">
       <div className='selectedImage'>
       <img
            src={`${API_IMAGE_URL}/${selectedImage}`}
            alt={project.project_title}
            className="w-full h-80 object-cover rounded-lg"
          />
          </div>

          <div className="main-image-container mb-4   lg:w-3/4  mx-auto  " >
          <img
            src={`${API_IMAGE_URL}/${selectedImage}`}
            alt={project.project_title}
            className="w-full h-80 object-cover rounded-lg "
          />
        
        </div> 


         
          <div className="thumbnail-gallery flex lg:flex-col gap-2 lg:gap-2 mt-2 ">
            {project.images.map((img, index) => (
              <img
                key={index}
                src={`${API_IMAGE_URL}/${img.image_url}`}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setSelectedImage(img.image_url)}
                className={`object-cover rounded-lg cursor-pointer border-2 ${
                  selectedImage === img.image_url ? 'border-yellow-500' : 'border-transparent'
                }`}
              />
            ))}
          </div>
          
        </div>

      
        <div className="mt-8 " >
        <button onClick={toggleDiv} className='project_button mt-4'  >
        {isVisible ? "Hide Content" : "Show Before and After"}
      </button>
       
          <h2 className="text-3xl font-bold mb-2">{project.project_title}</h2>
          <h4 className="text-lg mb-4">Company: {project.client_name}</h4>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Description</h4>
            <p>{project.description}</p>
          </div>

          <div className="mt-4">
            <h4 className="font-semibold text-lg mb-2">Project Time</h4>
            <p>{format(new Date(project.start_date), 'yyyy-MM-dd')} - {format(new Date(project.end_date), 'yyyy-MM-dd')}</p>
          </div>
        </div>
        </div>

)}





</div> 






        )}

      </div> 
     
      <div className="project-list  lg:w-1/4 mb-2">
   

      <div style={styles.listSection}>
      <h1 style={styles.title}>Projects</h1>
                <ul style={styles.placesList}>
                    {projects.map((place, index) => (
                       <Link to={`/projects/${place.id}`} key={place.id}>
                        <li
                            key={index}
                            style={styles.placeItem}
                            // onClick={() => handlePlaceClick()} 
                        >     <img
                        src={`${API_IMAGE_URL}/${place.images[0].image_url}`}
                        alt={place.project_title}
                        className="w-full h-40  rounded-lg"
                      />
                           <h3> {place.project_title}</h3> 
                        </li></Link>

                    ))}
                </ul>
            </div>

    </div>
    

 
      <Footer />
    </div>
  );
};

const styles = {
  container: {
      position: 'relative',
      width: '100vw', // Full viewport width
      // height: '100vh', 
      overflow: 'hidden',
      filter: "grayscale(100%)",
      
  },
  mapSection: {
      width: '100vw', // Map takes up full viewport width
      height: '100vh', // Map takes up full viewport height
  },
  map: {
      width: '100%',
      height: '100%',
  },
  listSection: {
      position: 'absolute', // Fixed position for the list
      top: '20%',
      right: '0',
      width: '20%',
      height: '70%',
      backgroundColor: 'rgba(0.1, 0.1, 0, 0.1)', // Semi-transparent background
      padding: '20px',
      overflowY: 'auto',
      zIndex:"0"
      
  },
  title: {
      textAlign: 'center',
      marginBottom: '20px',
      fontSize: '2.5rem',
      color:"Black",
     
      
  },
  placesList: {
      listStyle: 'none',
      padding: '0',
    
      

  },
  placeItem: {
    
      borderBottom: '1px solid gray',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'background-color 0.2s ease',
      color:'black',
      textAlign: 'left',
      height:"200px",
     
     
      
  },
  placeItemHover: {
      backgroundColor: 'black',
  },
};

export default ProjectDetail;

{/* <div key={project.id} className="construction-card  shadow-sm rounded-lg p-6  lg:w-3/4  mx-auto ">
<button onClick={() => navigate(-1)} style={{ cursor: 'pointer',color:"black",marginRight:"80%",backgroundColor:"white" ,fontSize:"30px"}} >
      ← 
    </button>

<ReactCompareImage 
leftImage={ `${API_IMAGE_URL}/${before}` }
rightImage={`${API_IMAGE_URL}/${after}`} 
sliderPositionPercentage={0.5}  
aspectRatio="wider"  

/>

<h2 className="text-2xl font-bold mb-2 mt-2">{project.project_title}</h2>
<p className="text-gray-600">{project.description}</p>
<h4 className="font-semibold text-lg mb-2">Description</h4>
            <p>{project.description}</p>

            <h4 className="font-semibold text-lg mb-2">Project Time</h4>
            <p>{format(new Date(project.start_date), 'yyyy-MM-dd')} - {format(new Date(project.end_date), 'yyyy-MM-dd')}</p>

</div> */}
