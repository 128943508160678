import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Main Swiper CSS
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules'; // Import required modules
import ReactCompareImage from 'react-compare-image';
import Navbar from './Nabar';





const imageList = [

];

const Contact = () => {
  const [bgImage, setBgImage] = useState(imageList[0]); // Initialize with the first image
  const swiperRef = useRef(null);

  const handleSlideChange = () => {
    const swiper = swiperRef.current.swiper;
    const activeIndex = swiper.activeIndex;
    setBgImage(imageList[activeIndex]); // Update the background image based on the active slide
  };

  return (
    <div>
      <Navbar />
      {/* Section with Dynamic Background Image */}
      <div
        className="dynamic-background"
       
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 0.5s ease-in-out',
          height: '100vh',
          position: 'relative',
        }}
      >



  <div className="swiper-container">
        <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay, EffectCards]} 
        onSlideChange={handleSlideChange} 
        navigation
        pagination={{ clickable: true }} 
        loop={false} 
        autoplay={{
            delay: 3000, 
            disableOnInteraction: false, 
        }}
        effect="cards" 
        className='slider-new'
    >
        {imageList.map((image, index) => (
            <SwiperSlide key={index}>
                <img 
                    src={image} 
                    alt={`Card ${index + 1}`} 
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }} 
                />  
            </SwiperSlide>
        ))}
    </Swiper>
    </div>

      </div>
     <section className="p-8 bg-gray-100">

    <div id="interior" className="">
    <h2 className="text-3xl font-bold text-center mb-8">Before and After Interiors</h2>
      <div className="grid grid-cols-3 gap-4">
        {/* {comparisons.map((item, index) => (
          <div key={index} className="flex justify-center">
            <div className="w-full md:w-3/3">
              <ReactCompareImage 
                leftImage={item.before} 
                rightImage={item.after} 
              />
            </div>
          </div>
        ))} */}
      </div>
    </div>
    </section>
    </div>
  );
};

export default Contact;
