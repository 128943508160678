import React from 'react';
import { FaPhone } from 'react-icons/fa';
import Header from './Header';
import Footer from './Footer';
import '../css/ServiceList.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-cube';
import "swiper/css/effect-coverflow";
import 'swiper/css'; // Import core styles
import 'swiper/css/navigation'; // Navigation styles
import 'swiper/css/pagination'; // Pagination styles
import 'swiper/css/effect-fade'; // Fade effect styles
import 'swiper/css/effect-cards'; // Import card effect styles
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards,EffectCoverflow} from 'swiper/modules';


const ResidentialInteriorDesign = () => {
    const images = [
        // furn_pic1,
        // furn_pic2,
        // furn_pic3
        
      ];
  return (
    <div className="bg-gray-100 min-h-screen py-10 ">
         <Header />
      {/* Hero Section */}
      {/* <section className="bg-cover bg-center bg-[url('https://path-to-image')] h-80 flex items-center justify-center text-white text-center px-4">
        <h1 className="text-4xl font-bold max-w-2xl">Residential Interior Design by Majestic Trading P.L.C</h1>
      </section> */}

      {/* Intro Section */}
      <div className='service-page-container'>


      <div className="service-page">
  <div className="service-hero">
    <div className="service-image">
    <div className=''>
     <div className="">
  <Swiper
 
  modules={[Navigation, Pagination, Autoplay, EffectCards]} 
  // onSlideChange={handleSlideChange} 
  navigation
  pagination={{ clickable: true }} 
  loop={false} 
  autoplay={{
      delay: 3000, 
      disableOnInteraction: false, 
  }}
  effect="coverflow" 
  className=' '
  style={{marginTop:"10%",height:"400px",width:"70%"}}
>
  {images.map((image, index) => (
      <SwiperSlide key={index}>
          <img 
               src={`${image}`}
              alt={`Card ${index + 1}`} 
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }} 
          />  
           {/* <button  className="button" onClick={handleRedirectInterior}>
                          Explore
                      </button> */}
      </SwiperSlide>
  ))}
</Swiper>
</div>
  </div>
    </div>
    {/* <div className="service-details">
      <h1 className="service-title">Our Premium Service</h1>
      <p className="service-description">
        Discover the benefits of our top-tier service, designed to provide 
        exceptional value and cater to your specific needs.
      </p>
      <ul className="service-features">
        <li>Feature 1: Quality Assurance</li>
        <li>Feature 2: 24/7 Support</li>
        <li>Feature 3: Customization Options</li>
      </ul>
      <button className="cta-button">Learn More</button>
    </div> */}
  </div>
</div>



  <section className="max-w-7xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-semibold text-gray-800">Elevate Your Living Spaces</h2>
        <p className="mt-4 text-gray-600 leading-relaxed">
          Majestic Trading P.L.C’s residential interior design services focus on turning your vision into a stunning reality. 
          Our talented designers craft interiors that are both functional and aesthetically captivating. We understand that your home 
          reflects your unique personality and lifestyle, and we design layouts that maximize functionality while maintaining 
          an open and inviting atmosphere.
        </p>
      </section>
 {/* Our Services Section */}
 <section className="max-w-7xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-semibold text-gray-800">Our Interior Design  Services</h2>
        <div className="mt-6 space-y-8">
          <div>
            <h3 className="text-2xl  text-gray-700">Living Room Design</h3>
            <p className="text-gray-600">Create a welcoming and stylish living area that reflects your personality and is perfect for entertaining.</p>
          </div>
          <div>
            <h3 className="text-2xl  text-gray-700">Kitchen and Dining</h3>
            <p className="text-gray-600">Our kitchen and dining designs blend practicality and elegance, making meal times a joy.</p>
          </div>
          <div>
            <h3 className="text-2xl  text-gray-700">Bedroom Retreats</h3>
            <p className="text-gray-600">Design your dream bedroom, a serene haven for relaxation and rejuvenation.</p>
          </div>
          <div>
            <h3 className="text-2xl  text-gray-700">Bathroom Elegance</h3>
            <p className="text-gray-600">Our bathroom designs combine functionality with luxury, offering spa-like experiences in your home.</p>
          </div>
          <div>
            <h3 className="text-2xl  text-gray-700">Custom Furnishings</h3>
            <p className="text-gray-600">Elevate your interiors with custom-designed furnishings, crafted to perfection.</p>
          </div>
          <div>
            <h3 className="text-2xl  text-gray-700">Lighting and Decor</h3>
            <p className="text-gray-600">We understand the importance of lighting and decor in setting the right ambiance, enhancing the overall atmosphere of your home.</p>
          </div>
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="max-w-7xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-semibold text-gray-800">Our Approach</h2>
        <div className="mt-6 space-y-6">
          <div>
            <h3 className="text-2xl font-semibold text-gray-700">Personalized Design</h3>
            <p className="text-gray-600">We begin each project with a thorough understanding of your preferences, needs, and aspirations. Our goal is to create designs that align perfectly with your taste and lifestyle.</p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold text-gray-700">Attention to Detail</h3>
            <p className="text-gray-600">From choosing the right color palettes to selecting the finest materials, we pay meticulous attention to detail. Every element of your home exudes sophistication.</p>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="max-w-7xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-semibold text-gray-800">Why Choose Majestic Trading P.L.C?</h2>
        <p className="mt-4 text-gray-600 leading-relaxed">
          With years of experience in residential interior design, Majestic Trading P.L.C has a proven track record of delivering exceptional results. 
          Our dedication to innovation, creativity, and quality has made us a trusted choice for homeowners seeking to transform their living spaces. 
          We bring a passion for design to every project, making each home a unique reflection of its owner’s style and personality.
        </p>
      </section>
    
   
 


      {/* Contact Us CTA */}
      {/* <section className="text-center mt-16">
        <p className="text-lg font-semibold mb-4 text-gray-800">Ready to bring your vision to life?</p>
        <button className="flex items-center px-8 py-3 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 transition-colors">
          <FaPhone className="mr-2" /> Contact Us
        </button>
      </section> */}
      </div>
       <Footer />
    </div>
  );
};

export default ResidentialInteriorDesign;
