import React,{useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
// import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import HomePage from './components/HomePage';
import Furniture from './components/Furniture';
import InteriorDesignBeforeAfter from './components/InteriorDesignBeforeAfter';
import ScrollImageComponent from './components/ScrollImageComponent';
import ImageSlider from './components/ImageSlider'; 
import CubeSlider from './components/CubeSlider';
import 'leaflet/dist/leaflet.css';
import ResidentialInteriorDesign from './components/ServicePage'
import ServiceList from './components/ServiceList';
import InteriorDesignList from './components/InteriorDesignList';
import FurnitureShowcase from './components/FurnitureShowcase';
import NewsPage from './components/NewsPage'
import Construction from './components/Construction';
import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from './config'; 
import Portfolio from './components/PortfolioSection';
import ProjectDetail from './components/ProjectDetail';
import stringSimilarity from 'string-similarity';
import ProjectMetaTags from './components/ProjectMetaTags';
import ProjectBYCategory from './components/projectByCategory'
import NewsDetail from './components/NewsDetail'
import Blogs from './components/Blog';
import BlogDetail from './components/BlogDetail';




function App() {

  const [finishingList, setFinishingList] = useState([]);
  const [interiorList, setInteriorList] = useState([]);
  const [woodWorkList, setWoodworkList] = useState([]);
  const [softwareList, setSoftwareList] = useState([]);
  const [allProject, setAllProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState([]);
  const [video , setVideo] = useState('');
  
  // Fetch the data from the API
  useEffect(() => {


    
    const fetchInterior = async () => {
      try {
      // Step 1: Define the Character Similarity Function
const characterSimilarity = (a, b) => {
  const aChars = a.toLowerCase().split('');
  const bChars = b.toLowerCase().split('');

  let commonChars = 0;

  // Count common characters
  aChars.forEach((char) => {
    if (bChars.includes(char)) {
      commonChars++;
      bChars.splice(bChars.indexOf(char), 1); // Remove character to avoid duplicate matching
    }
  });

  const maxLength = Math.max(a.length, b.length);
  return (commonChars / maxLength) * 100; // Return similarity as a percentage
};

// Step 2: Filter Categories by Similarity
const getSimilarCategories = (target, dataObject, threshold = 50) => {
  const result = [];

  // Loop over the keys in the object
  Object.keys(dataObject).forEach((key) => {
    const similarity = characterSimilarity(target, key);

    // If similarity is above the threshold, include it in the result array
    if (similarity >= threshold) {
      result.push({ key, similarity, data: dataObject[key] });
    }
  });

  return result.sort((a, b) => b.similarity - a.similarity); // Optional: Sort by similarity
};

// Usage
const response = await axios.get(`${API_BASE_URL}/projects/type?typeLimit=2000`);
const dataObject = response.data.data.data;
setAllProject((prevPortfolioList) => [...prevPortfolioList, response.data.data.pagination.totalCount]); 

for (let index = 0; index < dataObject.length; index++) {


 
const similarCategoriesDesign = getSimilarCategories(" Design", dataObject[index], 50); 
const similarCategoriesWoodWork = getSimilarCategories("Woodwork", dataObject[index], 50); 
const similarCategoriesfinishing = getSimilarCategories("Construction", dataObject[index], 50); 



if(similarCategoriesDesign.length > 0){
  setInteriorList(similarCategoriesDesign[0]); 
}
if(similarCategoriesWoodWork.length > 0){
  setWoodworkList(similarCategoriesWoodWork[0])
}
if(similarCategoriesfinishing.length> 0){
  setFinishingList(similarCategoriesfinishing[0])
}
// console.log("heyyyyyyyyyyyy")
// console.log("dataObject",dataObject[0][0])
// for (let index1 = 0; index1 < dataObject[index].length; index1++) {
//   console.log(dataObject[index])
//   setAllProject((prevPortfolioList) => [...prevPortfolioList, dataObject[index][index1]]); 
// }
// setAllProject((prevPortfolioList) => [...prevPortfolioList, similarCategoriesDesign[0]]); 
// setAllProject((prevPortfolioList) => [...prevPortfolioList, similarCategoriesWoodWork[0]]); 
// setAllProject((prevPortfolioList) => [...prevPortfolioList, similarCategoriesfinishing[0]]); 

}


       
        
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };


    const fetchVideo = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/video`);
        // console.log("this is video ",response.data.data.data[0].video_url)
        // for (let index = 0; index < response.data.data.length; index++) {
        //   console.log(response.data[index])
          setVideo(`${API_IMAGE_URL}/${response.data.data.data[0].video_url}`)
        // }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchInterior()
    fetchVideo()
  }, []);

    useEffect(() => {
    const fetchcategory = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/category`); 
        
        for (let index = 0; index < response.data.data.data.length; index++) {

          
          for (let index1 = 0; index1 < response.data.data.data[index]['sub_categories'].length; index1++) {
           
          setCategory((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]['sub_categories'][index1]]); 
          // setCategory((prevPortfolioList) => [...prevPortfolioList, ...index1]);
          }
        }
       
        
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };
    
    // fetchcategory();
  
  }, []);
  
  return (
    <Router>
      {/* <Header /> */}
      <ProjectMetaTags /> 
      <main>
        <Routes>
        <Route path="/" element={<HomePage video={video} interiorList={interiorList} woodWorkList={woodWorkList} finishingList={finishingList} allProject={allProject}/>} />
          <Route path="/aboutUs" element={<About />} />
          <Route path="/portfolio" element={<Portfolio category={category} />} />
          <Route path="/projects/type/:id" element={<ProjectBYCategory />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/interior" element={< ResidentialInteriorDesign interiorList={interiorList}/>} />
          <Route path="/technology" element={< ServiceList softwareList={softwareList} />} />
          <Route path="/woodwork" element={< InteriorDesignBeforeAfter woodWorkList={woodWorkList} />} />
         
          <Route path="/articles" element={<NewsPage />} />
          <Route path="/articles/:id" element={<NewsDetail />} />
          {/* <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogDetail />} /> */}
          <Route path="/construction" element={<Construction  finishingList={finishingList}/>} />
          <Route path="/services/:id" element={<Furniture />} />
          
       
          
        </Routes>
      </main>
    </Router>
  );
}

export default App;
