import React, { useEffect, useState,useRef } from 'react';

import { BrowserRouter as Router, Routes, Route, Link ,useNavigate} from 'react-router-dom';
import { Carousel } from "@material-tailwind/react";
import BeforeAfterSlider from 'react-before-after-slider-component';
import ReactCompareImage from 'react-compare-image';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './Footer';
import ProjectCounter from './ProjectCounter';
import InteriorCounter from './InteriorCounter';
import MapAndPlaces from './MapAndPlaces';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-cube';
import 'swiper/css'; // Import core styles
import 'swiper/css/navigation'; // Navigation styles
import 'swiper/css/pagination'; // Pagination styles
import 'swiper/css/effect-fade'; // Fade effect styles
import 'swiper/css/effect-cards'; // Import card effect styles
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules';
import CubeSlider from './CubeSlider';
import BeforeAfter from './BeforeAndAfter'
import Navbar from './Nabar';
import ScrollButtons from './ScrollButtons';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 
import axios from 'axios';
import { isEqual } from 'date-fns';


const images = [
  // furn_pic2,
  // furn_pic2,
  // furn_pic3,
  // furn_pic4
  
];
function HomePage(props) {
  console.log(props)
  const navigate = useNavigate();
  const [showText, setShowText] = useState(false); 
  const [showTextTwo, setShowTextTwo] = useState(false); 
  const [showTextWoodWork, setShowTextWoodWork] = useState(false); 
  const [showTextFinishing, setShowTextFinishing] = useState(false); 
  const timeoutRef = useRef(null); // Ref to store the timeout ID

  const sectionsRef = useRef([]); // Store references to the sections
 
  const swiperRef = useRef(null);
  const [titleVisible, setTitleVisible] = useState(false);
  const [bgImage, setBgImage] = useState(images[0]); 
  const [video , setVideo] = useState('');
  const [interiorPageTitle , setInteriorPageTitle] = useState();
  const [woodworkPageTitle , setWoodworkPageTitle] = useState();
  const [constructionPageTitle , setConstructionPageTitle] = useState();
  const [interiorPageSecTitle , setInteriorPageSecTitle] = useState();
  const [woodworkPageSecTitle , setWoodworkPageSecTitle] = useState();
  const [constructionSecPageTitle , setConstructionPageSecTitle] = useState();
  const [woodworkPageImage , setWoodworkPageImage] = useState();
  const [constructionPageImage , setConstructionPageImage] = useState();
  const [projectCounter ,setProjectCounter] = useState(0);
  const [interiorCounter ,setInteriorCounter] = useState(0);
  

  useEffect(() => {
    if(props.interiorList.length != 0){
    console.log(props.interiorList.data.length)
  for (let index = 0; index < props.interiorList.data.length; index++) {
    const element = props.interiorList.data[index];
    console.log("element", element)
    images[index +1] =`${API_IMAGE_URL}/${element.images[0].image_url}`
  }
}
}, [props.interiorList]);
 
  
  const handleSlideChange = () => {
      const swiper = swiperRef.current.swiper;
      const activeIndex = swiper.activeIndex;
      setBgImage(images[activeIndex]); 
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        setTitleVisible(true);
      }, 500); 
  
      return () => clearTimeout(timer);
    }, []);

  useEffect(() => {

    
      const options = {
          root: null, // The viewport
          threshold: 0.2, // Trigger when 10% of the element is visible
      };

      const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  entry.target.classList.add('visible'); // Add visible class when section is in view
              } else {
                  entry.target.classList.remove('visible'); // Remove visible class when section leaves view
              }
          });
      }, options);

      sectionsRef.current.forEach((section) => {
          if (section) {
              observer.observe(section); // Observe each section
          }
      });

      return () => {
          if (sectionsRef.current) {
             // sectionsRef.current.forEach((section) => observer.unobserve(section));
          }
      };
    }, []);
    useEffect(() => {

            // Step 1: Define the Character Similarity Function
const characterSimilarity = (a, b) => {
  const aChars = a.toLowerCase().split('');
  const bChars = b.toLowerCase().split('');

  let commonChars = 0;

  // Count common characters
  aChars.forEach((char) => {
    if (bChars.includes(char)) {
      commonChars++;
      bChars.splice(bChars.indexOf(char), 1); // Remove character to avoid duplicate matching
    }
  });

  const maxLength = Math.max(a.length, b.length);
  return (commonChars / maxLength) * 100; // Return similarity as a percentage
};

// Step 2: Filter Categories by Similarity
const getSimilarCategories = (target, dataObject, threshold = 50) => {
  const result = [];

  // Loop over the keys in the object
  Object.keys(dataObject).forEach((key) => {
    const similarity = characterSimilarity(target, key);

    // If similarity is above the threshold, include it in the result array
    if (similarity >= threshold) {
      result.push({ key, similarity, data: dataObject[key] });
    }
  });

  return result.sort((a, b) => b.similarity - a.similarity); // Optional: Sort by similarity
};

      const fetchVideo = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/video`);
          
          // for (let index = 0; index < response.data.data.length; index++) {
          //   console.log(response.data[index])
            setVideo(`${API_IMAGE_URL}/${response.data.data.data[0].video_url}`)
          // }
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      };

      const fetchPages = async () => {
        try {
           const response = await axios.get(`${API_BASE_URL}/pages/all`);
         console.log(response.data.data.data)
          for (let index = 0; index < response.data.data.data.length; index++) {
          //   console.log(response.data[index])
          const element = response.data.data.data[index];
          console.log(element)
          console.log(element.name)
         
          if(element.name.includes("interior")){
            for (let index1 = 0; index1 < element.sections.length; index1++) {
            if(element.sections[index1].name == "title"){
              setInteriorPageTitle(element.sections[index1].content)

            }
            if(element.sections[index1].name == "title_secondary"){
              setInteriorPageSecTitle(element.sections[index1].content)

            }
         
           
          }}
          else  if(element.name.includes("woodwork")){
            console.log("woodwork" ,element)
            
            for (let index1 = 0; index1 < element.sections.length; index1++) {
              if(element.sections[index1].name == "title"){
                setWoodworkPageTitle(element.sections[index1].content)
  
              }
              if(element.sections[index1].name == "title_secondary"){
                setWoodworkPageSecTitle(element.sections[index1].content)
  
              }
              if(element.sections[index1].section_type == "image"){
                console.log(element.sections[index1].cover)
                setWoodworkPageImage(element.sections[index1].cover)
  
              }
            }
          }
          else if(element.name.includes("construction")){
          
            for (let index1 = 0; index1 < element.sections.length; index1++) {
              if(element.sections[index1].name == "title"){
                setConstructionPageTitle(element.sections[index1].content)
  
              }
              if(element.sections[index1].name == "title_secondary"){
                setConstructionPageSecTitle(element.sections[index1].content)
  
              }
              if(element.sections[index1].section_type == "image"){
                setConstructionPageImage(element.sections[index1].cover)
  
              }
            }
          }

            
          }
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      };

      const fetchCounter = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/counter`);
          console.log("this is video ",response.data.data.data)
          for (let index = 0; index < response.data.data.data.length; index++) {
            const element = response.data.data.data[index];
            // console.log(element.project_type)
            // console.log(getSimilarCategories("all", "all", 50))
            // console.log(getSimilarCategories("interior", element.project_type, 50))
    
          if("all" ==  element.project_type){
            console.log(true)
            console.log(index)
            setProjectCounter(element.amount)
           
          }
          if("interior" == element.project_type){
            setInteriorCounter(element.amount)
           
          }
            
          }
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      };

      fetchVideo();
      fetchPages();
      fetchCounter();

      
  }, []);

  const handleMouseEnter = () => {
    // Set a timeout to show the text after 1 second (1000 ms)
    timeoutRef.current = setTimeout(() => {
        setShowText(true);
    }, 1000);
};

const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current); // Clear the timeout when mouse leaves
    setShowText(false); // Immediately hide the text
};


const handleMouseEnterTwo = () => {
  // Set a timeout to show the text after 1 second (1000 ms)
  timeoutRef.current = setTimeout(() => {
      setShowTextTwo(true);
  }, 1000);
};

const handleMouseLeaveTwo = () => {
  clearTimeout(timeoutRef.current); // Clear the timeout when mouse leaves
  setShowTextTwo(false); // Immediately hide the text
};

const handleMouseEnterWoodWork = () => {
  // Set a timeout to show the text after 1 second (1000 ms)
  timeoutRef.current = setTimeout(() => {
      setShowTextWoodWork(true);
  }, 1000);
};

const handleMouseLeaveWoodWork = () => {
  clearTimeout(timeoutRef.current); // Clear the timeout when mouse leaves
  setShowTextWoodWork(false); // Immediately hide the text
};
const handleMouseEnterFinishing = () => {
  // Set a timeout to show the text after 1 second (1000 ms)
  timeoutRef.current = setTimeout(() => {
      setShowTextFinishing(true);
  }, 1000);
};

const handleMouseLeaveFinishing = () => {
  clearTimeout(timeoutRef.current); // Clear the timeout when mouse leaves
  setShowTextFinishing(false); // Immediately hide the text
};
const handleRedirectInterior = () => {
  window.location.href = '/interior';
};






  return (
    <div className="home-page">
 <Navbar />
 <ScrollButtons />
<div className="">
            {/* {Array.from({ length: 5 }).map((_, index) => ( */}
                <div
                    key={0}
                    ref={(el) => sectionsRef.current[0] = el}
                    className="section"
                >
                   
                    <div className="bottom-line"></div>
{/* {showTextTwo && ( */}
  <div>


<ProjectCounter targetCount={projectCounter} iconColor="white" />

</div>
 {/* )} */}
    
    <div className="video-container" id="videoContainer"
     onMouseEnter={handleMouseEnterTwo}  
     onMouseLeave={handleMouseLeaveTwo}
      >
        <video

          autoPlay
          loop
          muted
          playsInline
          className=""
          id="homeVideo"
          key={props.video}
        >
          <source src={props.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="content-overlay">

        </div>
        <div className="gradient-overlay"></div>
      </div>

                </div>
            {/* ))} */}
            
        </div>
        <div
                    key={1}
                    ref={(el) => sectionsRef.current[1] = el}
                    className="section"
                >

      <div className="image-container " id="imageContainer"
       style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)),url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'background-image 0.5s ease-in-out',
        height: '100vh',
        position: 'relative',
      }}
       onMouseEnter={handleMouseEnter}  
       onMouseLeave={handleMouseLeave} >
     
        {/* <img src={interior} alt="Full View Image" id="fullImage" /> */}
      
        
        <div className='textStyle ' style={{color:"white",paddingLeft:"10%",paddingTop:"19%"}}>
        {/* {showText && (  */}
          <div className=''>
            {/* <MyComponent textToType={"Interior design "}/>  */}

            <h1 id = 'tit_display' className={` xl:text-8xl  text-center  ${titleVisible ? 'visible' : ''}`}>
        {interiorPageTitle }
      </h1>

      <h1 id = 'tit_display1' className={`  text-center  ${titleVisible ? 'visible' : ''}`}>
      {interiorPageTitle}
      </h1>
            </div>

        
          {/* )} */}
           

   {/* {showText && (  */}
    <div id='title-des'className='text-left pt-0'>
    <h1>  {interiorPageSecTitle}.</h1>
    </div>
    
    {/* )} */}
        </div>

    {/* {showText && ( */}
        <div style={{height:"300px"}} className=''>
           <div className="swiper-container ">
        <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay, EffectCards]} 
        onSlideChange={handleSlideChange} 
        navigation
        pagination={{ clickable: true }} 
        loop={false} 
        autoplay={{
            delay: 3000, 
            disableOnInteraction: false, 
        }}
        effect="cards" 
        className='slider-new '
    >
        {images.map((image, index) => (
            <SwiperSlide key={index}>
                <img 
                     src={`${image}`}
                    alt={`Card ${index + 1}`} 
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }} 
                />  
                 <button  className="button" onClick={handleRedirectInterior}>
                                Explore
                            </button>
            </SwiperSlide>
        ))}
    </Swiper>
    </div>
        </div>
    {/* )} */}

        {/* {showText && ( */}
    <div>
    <div className="interior-line"></div>
    <InteriorCounter targetCount={interiorCounter} iconColor="white" />
    </div>  
   {/* )} */}
      

    
    </div>
    </div>
    <div
                    key={2}
                    ref={(el) => sectionsRef.current[2] = el}
                    className="section"
                >
   
    <div className="image-container2 background-image_wood" id="imageContainer" 
           onMouseEnter={handleMouseEnterWoodWork}  
           onMouseLeave={handleMouseLeaveWoodWork}
           style={{ 
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)), url(${API_IMAGE_URL}/${woodworkPageImage})`,
            backgroundSize: 'cover',
           }}
           >
        {/* <img src={woodwork} alt="Full View Image" id="fullImage2" /> */}

        <div className='textStyle' style={{color:"white",paddingLeft:"10%",paddingTop:"19%"}}>
        {/* {showTextWoodWork && (  */}

          
        <div >

<h1  id = 'tit_display' className={` text-8xl  text-center mb-2 ${titleVisible ? 'visible' : ''}`}  >
 
  {woodworkPageTitle}

      </h1>

      <h1 id = 'tit_display1' className={`  text-center  ${titleVisible ? 'visible' : ''}`}>
      {woodworkPageTitle}
      </h1>
         
          </div>
        {/* )} */}
          <h2 style={styles.title}></h2>

 {/* {showTextWoodWork && (  */}
  <div className=''>
  <h1 id='title-des' className='text-left'>  {woodworkPageSecTitle}</h1>
  </div>
  
  {/* )} */}
      </div>

  {/* {showTextWoodWork && ( */}
      <div>
        <CubeSlider woodWorkList={props.woodWorkList} />
      </div>
  {/* )} */}

      {/* {showTextWoodWork && ( */}
  <div>
 
  {/* <InteriorCounter targetCount={100} iconColor="#3cc7f3" /> */}
  </div>  
 {/* )} */}

    </div>
    </div>

    <div
                    key={3}
                    ref={(el) => sectionsRef.current[3] = el}
                    className="section"
                >

 
    <div className="image-container2 background-image_finishing" id="imageContainer"
     onMouseEnter={handleMouseEnterFinishing}  
     onMouseLeave={handleMouseLeaveFinishing}
     style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)), url(${API_IMAGE_URL}/${constructionPageImage})`,
      backgroundSize: 'cover',
     }}
    //  style={{backgroundImage:}}
    >
        {/* <img src={finishing} alt="Full View Image" id="fullImage2" /> */}

        <div className='textStyle' style={{color:"white",paddingLeft:"10%",paddingTop:"19%"}}>

        {/* {showTextFinishing && (  */}
          <div>
            {/* <MyComponent textToType={"Interior design "}/>  */}

            <h1 id = 'tit_display' className={` text-8xl  text-center  ${titleVisible ? 'visible' : ''}`}>
       
        {constructionPageTitle}
      </h1>
      <h1 id = 'tit_display1' className={` text-8xl  text-center  ${titleVisible ? 'visible' : ''}`}>
      {constructionPageTitle}
      </h1>
            </div>

        
          {/* )} */}
           

   {/* {showTextFinishing && (  */}
    {/* <div className='hoverText'>
    <h2> {constructionSecPageTitle}.</h2>
    </div> */}

    <div className=''>
  <h1 id='title-des' className='text-left hover:gray-200'>  {constructionSecPageTitle}</h1>
  </div>
    
    {/* )} */}
        </div>
        {/* {showTextFinishing && (  */}
        <div>
      <BeforeAfter finishingList={props.finishingList}/>
      </div>
      {/* )} */}

 

      {/* {showTextFinishing && ( */}
  <div>
 
  {/* <InteriorCounter targetCount={1300} iconColor="black" /> */}
  </div>  
 {/* )} */}

    </div>
    </div>

    <div
                    key={4}
                    ref={(el) => sectionsRef.current[4] = el}
                    className="section"
                >
    <div className="image-container2" id="imageContainer">
       <MapAndPlaces />
    </div>
    </div>
    

{/* <section className="bg-hero-pattern text-white h-[40rem] flex  items-center justify-center  " > */}


  {/* <div className="">
    <h1 className="text-5xl font-bold mb-4">Welcome to Majestic</h1>
    <p className="text-xl">We provide the best services for you.</p> */}
    {/* <img src={home_pic1} alt="Logo"  /> */}
    {/* <button className="mt-6 bg-white text-blue-500 py-2 px-6 rounded-full hover:bg-gray-200">
      Get Started
    </button> */}
  {/* </div>
</section> */}

{/* Content Section */}


{/* <PortfolioSection /> */}



  
{/* <section id="furniture" className="p-8 ">
<h2 className="text-4xl font-bold text-center mb-8">Our Furniture Collection</h2>

  <Slider {...settings}>
                {furnitureItems.map((item, index) => (

<div key={index} className="relative group mx-4">
<img src={item.imageUrl} alt={item.name} className="w-full h-60 object-cover rounded-lg shadow-lg" />
<div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center">
  <h3 className="text-white text-xl font-bold">{item.name}</h3>
  <p className="text-gray-300">{item.description}</p>
  
</div>
</div>
                ))}                
              </Slider>

    </section> */}



    {/* <section className="p-8 bg-gray-100">

    <div id="interior" className="">
    <h2 className="text-3xl font-bold text-center mb-8">Before and After Interiors</h2>
      <div className="grid grid-cols-3 gap-4">
        {comparisons.map((item, index) => (
          <div key={index} className="flex justify-center">
            <div className="w-full md:w-3/3">
              <ReactCompareImage 
                leftImage={item.before} 
                rightImage={item.after} 
              />
            </div>
          </div>
        ))}
      </div>
    </div>
    </section> */}


    {/* {Testimonials} */}
{/* <Testimonials /> */}
{/* {Our Clients} */}
{/* <OurClients /> */}
{/* Footer */}
{/* <InteriorDesignBeforeAfter/> */}
<Footer />

    </div>
  );
}
const styles = {
  hoverDiv: {
     
      backgroundColor: '#f0f0f0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid #ccc',
      position: 'absolute', 
      top:"50%",
      left:'0',
  },
  title: {
    textAlign: 'center',
      // fontSize: '3rem',
      // marginBottom: '10px',
     
  },
  hoverText: {
      fontSize: '1.5rem',
      color: 'white',
  },

};

export default HomePage;






