// TruncateText.js
import React from "react";

function TruncateText({ text, limit }) {
  const truncatedText = text.length > limit ? text.slice(0, limit) + "..." : text;

  return <p>{truncatedText}</p>;
}

export default TruncateText;
