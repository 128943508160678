import React , {useState, useEffect} from 'react';
import './LogoInteractive.css';
import logo from '../images/logo.png';



const LogoInteractive = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsLoaded(true), 1000); // Delay before showing the logo
    }, []);

    return (
        <div className="logo-container">
            <img 
                src={logo}
                alt="Logo" 
                className={`interactive-logo ${isLoaded ? 'visible' : ''}`}
               
            />
        </div>
    );
};

export default LogoInteractive;
