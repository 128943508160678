import React, { useState, useEffect} from 'react';
import { useParams, useNavigate,Link } from 'react-router-dom';
import '../css/ServiceList.css';
import Footer from './Footer';
import Header from './Header';
import { format } from 'date-fns';
import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';



const NewsPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };
    // Fetch the data from the API
useEffect(() => {
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/blogs/${id}`); 
 
      setBlogsList(response.data.data); 
      setLoading(false);
    } catch (err) {
      setError('Failed to load data');
      setLoading(false);
    }
  };

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/news/${id}`); 
 
      setBlogsList(response.data.data); 
      setLoading(false);
    } catch (err) {
      setError('Failed to load data');
      setLoading(false);
    }
  };

  fetchBlogs();
  fetchNews()
}, []);



  return (
    <div>
         <Header />
         <HelmetProvider>
      <Helmet>
          <title>{blogsList.title}</title>
          <meta name="description" content={blogsList.snippet} />
          <meta property="og:title" content={blogsList.title} />
          <meta property="og:description" content={blogsList.snippet} />
          <meta name="twitter:card" content={blogsList.snippet} />
        </Helmet>
        </HelmetProvider>
  
    <div className="news-page-container ">
    <button onClick={() => navigate(-1)} style={{ cursor: 'pointer',color:"black",marginRight:"80%",backgroundColor:"white" ,fontSize:"30px"}} >
      ← 
    </button>

    <div className="" >

<div className="" onClick={(e) => e.stopPropagation()}>


  <div className="">
  <img
              src={`${API_IMAGE_URL}/${blogsList.cover_image}`}
              alt={blogsList.title}
              className="w-full h-80 object-cover rounded-lg"
            />
  <h1>{blogsList.title}</h1>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        {/* <img
            src={`${API_IMAGE_URL}/${selectedProject.author_image}`}
          alt={selectedProject.author_name}
          style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
        /> */}
        <div>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{blogsList.written_by}</p>
          <p style={{ margin: 0, color: 'gray' }}>{new Date(blogsList.date_published).toLocaleDateString()}</p>
        </div>
      </div>
  
      <div dangerouslySetInnerHTML={{ __html: blogsList.content }} />
    

         
          </div>
  {/* <div dangerouslySetInnerHTML={{ __html: selectedProject.content }} /> */}
  </div>
</div>
/</div>











      

    <Footer />
    </div>
  );
};

export default NewsPage;
