import React,{useEffect,useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules'; // Include the EffectFade module
import { API_BASE_URL, API_IMAGE_URL } from '../config';
import axios from 'axios';

const images = [  
];

const OurClients = (props) => {

  const [partnerLink, setPartnerLink] = useState([])

  useEffect(() => {

    const fetchProjectSocialMedia = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/social`);
        console.log(response.data.data.length)
        for (let index = 0; index < response.data.data.length; index++) {
         if(response.data.data[index].link_type == "partners"){
           // setPartnerLink((pre)=>[...pre,response.data.data[index]])
            images[index +1] =`${API_IMAGE_URL}/${response.data.data[index].social_icon}`;
             
            
        }}
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };
     fetchProjectSocialMedia();
  }, []);


  return (
    <section className="bg-gray-200 py-8">
      <div className="container mx-auto pl-4">
        <h2 className="text-3xl font-bold text-center mb-2">Our Clients</h2>
        {/* <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 items-center justify-center">
          {clients.map((client, index) => (
            <div key={index} className="flex justify-center">
              <img src={client.logo} alt={client.name} className="h-16 w-auto object-contain" />
            </div>
          ))}
        </div> */}
            <div className="">
      <Swiper
         modules={[Navigation, Pagination, Autoplay, EffectCards]} 
        slidesPerView={6}
        breakpoints={{
          640: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 6,
          },
          0: {
            slidesPerView: 2,
          },
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        className="flex justify-center"
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false, 
      }}
      >


{images.map((image,index) => (
            <SwiperSlide key={index}>
              <div className="">
                <img 
                     src={image}
                    alt={`Card ${index + 1}`} 
                    className=" ="
                    style={{height:"70px",width:"70px", borderRadius:"50px"}}
                    
                    
                />  
                            </div>
            </SwiperSlide>
        ))}

     

  
      </Swiper>
    </div>
      </div>
    </section>
  );
};

export default OurClients;
