import React ,{useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules'; // Include the EffectFade module
// import './ImageSlider.css';

import { API_BASE_URL , API_IMAGE_URL} from '../config'; 

const images = [
  // furn_int1,
  // furn_int2,
  // furn_int3,
  // furn_int4,
  // furn_int5,

  
];
const CubeSlider = (props) => {

  const handleRedirectWoodwork = () => {
    window.location.href = '/woodwork';
  };

  // useEffect(() => {
    if(props.woodWorkList.length != 0){
    console.log(props.woodWorkList.data.length)
  for (let index = 0; index < props.woodWorkList.data.length; index++) {
    const element = props.woodWorkList.data[index];
   
    images[index +1] =`${API_IMAGE_URL}/${element.images[0].image_url}`;
  }
}
// }, [props]);

  return (
    <div className="image-slider">
      <Swiper
         modules={[Navigation, Pagination, Autoplay, EffectCards]} 
        slidesPerView={4}
        breakpoints={{
          // When the window is >= 640px, show 2 slides
          640: {
            slidesPerView: 2,
          },
          // When the window is >= 768px, show 3 slides
          768: {
            slidesPerView: 4,
          },
          // When the window is < 640px, show 1 slide (mobile view)
          0: {
            slidesPerView: 1,
          },
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        className="mySwiper"
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false, 
      }}
      >
        {/* Example slides */}
        {/* <SwiperSlide>
          <div className="slide-content">
            <img src={furn_int1} alt="Image 1" />
            <div className="hover-content">
              <h3>Furniture 1</h3>
              <button className="hover-button">Explore</button>
            </div>
          </div>
        </SwiperSlide> */}


{images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content">
                <img 
                    src={`${image}`}
                    alt={`Card ${index + 1}`} 
                    // style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }} 
                />  
                 <button  className="button" onClick={handleRedirectWoodwork}>
                                Explore
                            </button>
                            </div>
            </SwiperSlide>
        ))}

     

  
      </Swiper>
    </div>
  );
};

export default CubeSlider;






