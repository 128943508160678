import React ,{useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules'; // Include the EffectFade module
// import './ImageSlider.css';

import { API_BASE_URL , API_IMAGE_URL} from '../config'; 

const images = [
//   furn_int1,
  // furn_int2,
  // furn_int3,
  // furn_int4,
  // furn_int5,

  
];
const PartnerSlider = (props) => {

  const handleRedirectWoodwork = () => {
    window.location.href = '/woodwork';
  };


// }, [props]);

  return (
    <div className="image-slider">
      <Swiper
         modules={[Navigation, Pagination, Autoplay, EffectCards]} 
        slidesPerView={4}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 4,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        className="mySwiper"
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false, 
      }}
      >


{props.partnerLink.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content">
                <img 
                    src={`${image}`}
                    alt={`Card ${index + 1}`} 
                    // style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }} 
                />  
                 <button  className="button" onClick={handleRedirectWoodwork}>
                                Explore
                            </button>
                            </div>
            </SwiperSlide>
        ))}

     

  
      </Swiper>
    </div>
  );
};

export default PartnerSlider;






