import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import '../css/Navbar.css'; 
import LogoInteractive from './LogoInteractive'
import { FaCaretDown } from 'react-icons/fa';
import axios from 'axios';
import { API_BASE_URL , API_IMAGE_URL} from '../config'; 

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);  // For mobile view
  const [activeMenu, setActiveMenu] = useState(null);
  const [visibleItems, setVisibleItems] = useState([]); // To track visible submenu items
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [menu,setMenu]= useState([])
  const [services, setServices] =useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(()=>{

    const fetchProjectSocialMedia = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/social`);
        console.log(response.data.data.length)
        for (let index = 0; index < response.data.data.length; index++) {
          console.log(response.data[index])
          if(response.data.data[index].link_type == "menu"){
          setMenu((pre)=>[...pre,response.data.data[index]])
          }
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };
    fetchProjectSocialMedia()

    const fetchcategory = async () => {
      try {
         const response = await axios.get(`${API_BASE_URL}/services`); 

        console.log("navbar  length" ,response.data.data.data.length)
        
        for (let index = 0; index < response.data.data.data.length; index++) {
          console.log("this is service ")
    console.log(response.data.data.data[index].service_type)

          setServices((prevPortfolioList) => [...prevPortfolioList, response.data.data.data[index]]); 

        }
       
        
        setLoading(false);
      } catch (err) {
        setError('Failed to load data');
        setLoading(false);
      }
    };
    
    fetchcategory();
  },[]);


  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleMouseEnter = (menu) => {
    setActiveMenu(menu);
    // Show submenu items step by step
    const items = ['Finishing', 'Interiors Design', 'WoodWork','Software Development'];
    let delay = 0;

    items.forEach((item, index) => {
      setTimeout(() => {
        setVisibleItems((prev) => [...prev, item]);
      }, delay);
      delay += 200; // Increase delay for each item
    });
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    setVisibleItems([]); // Hide all submenu items
  };

  const menuItems = [
    { name: 'Home' },
    { name: 'Services' },
    { name: 'About Us' },
    { name: 'Contact' },
  ];


  return (
    <header className="header  headerColor" >
      <nav className={`  nav ${isOpen ? 'open' : ''}`}>
        <ul className="nav-links left">
        <li  className="menu-item"
          onMouseEnter={() => setHoveredIndex(0)}
          onMouseLeave={() => setHoveredIndex(null)}
          style={{
            color: hoveredIndex === 0 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
          }}>
               <a className =" " href="/">{menuItems[0].name}</a>
              </li>
            

        <li
          className="navbar-item "
          onMouseEnter={() => {handleMouseEnter('services') ; setHoveredIndex(1); }}
          onMouseLeave={()=>{handleMouseLeave();setHoveredIndex(null);}}
          style={{
            color: hoveredIndex === 1 || hoveredIndex === null ? 'white' : 'gray', 
          }}>
        
          <a href="#" className='menu-item'><span>Services <FaCaretDown  className="dropdown-icon"/> </span></a>
          
          {activeMenu === 'services' && (
             <div className='sub_menu'>
             <div className="line-bn"></div>
           <ul className="submenu">
            {/* <Link to="interior">Design</Link> */}
            {services.map((project) => (
             <li> <Link to={`/services/${project.id}`} key={project.id}>
                {project.service_type}
                </Link>
                </li>
              
              //  <li><a href="/interior"><h2>{project.title}</h2></a></li>
            ))}

              {/* <li><a href="/interior"><h2>Design</h2></a></li>
              <li><a href="/woodwork"><h2>WoodWork</h2></a></li>
              <li><a href="/construction"><h2>Construction</h2></a></li>
              <li><a href="/technology"><h2>Technologies</h2></a></li> */}
            </ul>
           
            </div>
          )}
          </li>
        
      
<li  className="menu-item" 
  onMouseEnter={() => setHoveredIndex(2)}
  onMouseLeave={() => setHoveredIndex(null)}
  style={{
    color: hoveredIndex === 2 || hoveredIndex === null ? 'white' : 'gray',
  }}>

<a href="/portfolio" >Portfolio</a>
</li>


        </ul>
        <div className="logo-container">
        <div className="line"></div>
        <LogoInteractive />
        <div className="line"></div>
        </div>
        <ul className="nav-links right">
 
     
        <li  className="menu-item"

onMouseEnter={() => setHoveredIndex(3)}
onMouseLeave={() => setHoveredIndex(null)}
style={{
  color: hoveredIndex === 3 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
}}>

<a href="/articles" >Articles</a>
</li>

{/* <li  className="menu-item"

onMouseEnter={() => setHoveredIndex(6)}
onMouseLeave={() => setHoveredIndex(null)}
style={{
  color: hoveredIndex === 6 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
}}>

<a href="/blogs" >Blogs</a>
</li> */}

<li  className="menu-item"
 onMouseEnter={() => setHoveredIndex(4)}
 onMouseLeave={() => setHoveredIndex(null)}
 style={{
   color: hoveredIndex === 4 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
 }}>
<a href="/aboutUs">About Us</a></li>

{menu.length > 0 && (
   <li
   className="navbar-item "
   onMouseEnter={() => {handleMouseEnter('additional_link') ; setHoveredIndex(5); }}
   onMouseLeave={()=>{handleMouseLeave();setHoveredIndex(null);}}
   style={{
     color: hoveredIndex === 5 || hoveredIndex === null ? 'white' : 'gray', 
   }}>
 
   <a href="#" className='menu-item'><span>Additional Link <FaCaretDown  className="dropdown-icon"/> </span></a>
   {activeMenu === 'additional_link' && (
     <div className='sub_menu'>
       <div className="line-bn"></div>
     <ul className="submenu">
     {menu.map((link) => (
       <li><a href={link.social_url}><h2>{link.social_name}</h2></a></li>
     ))}

     </ul>
     </div>
   )}
 </li>
)}

        </ul>
      </nav>
      
 
      {/* Hamburger Icon to toggle drawer */}
      <div className='toggle'>
      <button className="menu-toggle" onClick={toggleDrawer}>
        {isDrawerOpen ? '✖' : '☰'}
      </button>
      </div>

      {/* Drawer Menu (will show on small screens) */}
      <div className={`drawer ${isDrawerOpen ? 'open' : ''}`} >
      <ul className="nav-links left">

      <div className="logo-container mt-4" style={{}}>
        <div className="line"></div>
        <LogoInteractive />
        <div className="line"></div>
        </div>
        
        <li  className="menu-item mt-4"
          onMouseEnter={() => setHoveredIndex(0)}
          onMouseLeave={() => setHoveredIndex(null)}
          style={{
            marginTop:"70px",
            color: hoveredIndex === 0 || hoveredIndex === null ? 'white' : 'gray', 
          }}>
               <a className =" " href="/">{menuItems[0].name}</a>
              </li>
            

        <li
          className="navbar-item "
          onMouseEnter={() => {handleMouseEnter('services') ; setHoveredIndex(1); }}
          onMouseLeave={()=>{handleMouseLeave();setHoveredIndex(null);}}
          style={{
            color: hoveredIndex === 1 || hoveredIndex === null ? 'white' : 'gray', 
          }}>
        
          <a href="#" className='menu-item'><span>Services <FaCaretDown  className="dropdown-icon"/> </span></a>
          
          {activeMenu === 'services' && (
             <div className=''>
             <div className="line-bn"></div>
           <ul className="">
            {/* <Link to="interior">Design</Link> */}
            {services.map((project) => (
             <li> <Link to={`/services/${project.id}`} key={project.id}>
                {project.service_type}
                </Link>
                </li>
              
              //  <li><a href="/interior"><h2>{project.title}</h2></a></li>
            ))}

              {/* <li><a href="/interior"><h2>Design</h2></a></li>
              <li><a href="/woodwork"><h2>WoodWork</h2></a></li>
              <li><a href="/construction"><h2>Construction</h2></a></li>
              <li><a href="/technology"><h2>Technologies</h2></a></li> */}
            </ul>
           
            </div>
          )}
          </li>
        
      
<li  className="menu-item" 
  onMouseEnter={() => setHoveredIndex(2)}
  onMouseLeave={() => setHoveredIndex(null)}
  style={{
    color: hoveredIndex === 2 || hoveredIndex === null ? 'white' : 'gray',
  }}>

<a href="/portfolio" >Portfolio</a>
</li>


        </ul>
       
        <ul className="nav-links right">
 
     
        <li  className="menu-item"

onMouseEnter={() => setHoveredIndex(3)}
onMouseLeave={() => setHoveredIndex(null)}
style={{
  color: hoveredIndex === 3 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
}}>

<a href="/articles" >Articles</a>
</li>

{/* <li  className="menu-item"

onMouseEnter={() => setHoveredIndex(6)}
onMouseLeave={() => setHoveredIndex(null)}
style={{
  color: hoveredIndex === 6 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
}}>

<a href="/blogs" >Blogs</a>
</li> */}


<li  className="menu-item"
 onMouseEnter={() => setHoveredIndex(4)}
 onMouseLeave={() => setHoveredIndex(null)}
 style={{
   color: hoveredIndex === 4 || hoveredIndex === null ? 'white' : 'gray', // Color for hovered item and others
 }}>
<a href="/aboutUs">About Us</a></li>

{menu.length > 0 && (
   <li
   className="navbar-item "
   onMouseEnter={() => {handleMouseEnter('additional_link') ; setHoveredIndex(5); }}
   onMouseLeave={()=>{handleMouseLeave();setHoveredIndex(null);}}
   style={{
     color: hoveredIndex === 5 || hoveredIndex === null ? 'white' : 'gray', 
   }}>
 
   <a href="#" className='menu-item'><span>Additional Link <FaCaretDown  className="dropdown-icon"/> </span></a>
   {activeMenu === 'additional_link' && (
     <div className=''>
       <div className="line-bn"></div>
     <ul className="">
     {menu.map((link) => (
       <li><a href={link.social_url}><h2>{link.social_name}</h2></a></li>
     ))}

     </ul>
     </div>
   )}
 </li>
)}

        </ul>
      </div>
    </header>
  );
};

export default Header;



