import React,  {useContext }from 'react';
import '../css/ServiceList.css';
import Navbar  from './Nabar';
import Header from './Header';
import Footer from './Footer';

const icon =  '💻';



const ServiceList = (props) => {
  return (
    <div className='bg-gray-100'>
        <Header />
    <div className="service-list-container">
        
      <h1 className="text-4xl font-bold text-center mb-8">Software Products</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {props.softwareList.map((service) => (
          <div key={service.id} className="service-card  shadow-lg rounded-lg p-6 text-center">
            <div className="service-icon text-6xl mb-4">{icon}</div>
            <h2 className="service-title text-2xl font-bold mb-2">{service.project_title}</h2>
            <p className="service-description text-gray-600">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default ServiceList;
