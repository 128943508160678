import React, { useRef, useState ,useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Main Swiper CSS
import { Navigation, Pagination, Autoplay, EffectFade ,EffectCube,EffectCards} from 'swiper/modules'; // Import required modules


import { API_BASE_URL , API_IMAGE_URL} from '../config'; 





const images = [

];

const BeforeAfter = (props) => {
  //const [bgImage, setBgImage] = useState(imageList[0]); 
  const swiperRef = useRef(null);

  const handleSlideChange = () => {
    const swiper = swiperRef.current.swiper;
    const activeIndex = swiper.activeIndex;
    //setBgImage(imageList[activeIndex]); 
  };

  const handleRedirectFinishing = () => {
    window.location.href = '/construction';
  };



  
  // useEffect(() => {
    if(props.finishingList.length != 0){
    console.log(props.finishingList.data.length)
  for (let index = 0; index < props.finishingList.data.length; index++) {
    const element = props.finishingList.data[index];

    images[index +1] =`${API_IMAGE_URL}/${element.images[0].image_url}`;
  }
}
// }, [props]);


  return (


    <div className="image-slider">
    <Swiper
       modules={[Navigation, Pagination, Autoplay, EffectCards]} 
      slidesPerView={4}
      breakpoints={{
        // When the window is >= 640px, show 2 slides
        640: {
          slidesPerView: 2,
        },
        // When the window is >= 768px, show 3 slides
        768: {
          slidesPerView: 4,
        },
        // When the window is < 640px, show 1 slide (mobile view)
        0: {
          slidesPerView: 1,
        },
      }}
      spaceBetween={10}
      loop={true}
      navigation={true}
      pagination={{ clickable: true }}
      className="mySwiper"
      autoplay={{
        delay: 3000, 
        disableOnInteraction: false, 
    }}
    >
      
    



        {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content">
                <img 
                     src={`${image}`}
                    alt={`Card ${index + 1}`} 
                    
                />  
                 <button  className="button" onClick={handleRedirectFinishing}>
                                Explore
                            </button>
                            </div>
            </SwiperSlide>
        ))}

 
    




      </Swiper>



    {/* <div className="image-slider">
 
     <section className="p-8 ">

    <div  className="mySwiper">
    <h2 className="text-3xl font-bold text-center mb-8">Before and After Interiors</h2>
      <div className="grid grid-cols-3 gap-4">
        {comparisons.map((item, index) => (
          <div key={index} className="flex justify-center slide-content">
            <div className="w-full md:w-3/3">
              <ReactCompareImage 
                leftImage={item.before} 
                rightImage={item.after} 
              />
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>
    </div> */}

      </div>


  );
};

export default BeforeAfter;






